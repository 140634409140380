import React,{useEffect,useState} from "react";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon } from '@iconify/react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import {postCartList,postCartUpdate,postCartCount,postCartListDelete,postCartDelete,postCouponCode} from "../services/serviceapi";
import {useCookies,Cookies} from 'react-cookie';

const Cart = () => {
  
  const [responseSuccess,respSuccess] = useState([]);
  const [count, setCount] = useState(1);
  const [responseCount,respCount] = useState(0);
  const [isLoading,setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isEmpty, setCartEmpty] = useState(true);
  const [cc,setCC] = useState("");
  const [coupon_code,setCouponCode] = useState('');
  const [coupon_message,setCouponMessage] = useState('');
  const [coupon_amount,setCouponAmount] = useState(0);
  const [invalidcc,setInvalidCC] = useState("");
  const [ccApplied,setCCApplied] = useState('');
  const [ccAmount,setCCAmount] = useState(0);
  const defData = {cc:''};

  //const [guest_id,setguestid] = useState(0);

  const [formErrors,formError] = useState(defData);

  const confirmClose = ()=> {
    setShowConfirm(false);
  }

  const min = 1;
  const max = 500;
  const [cookies, setCookie] = useCookies(['SESSIONINFO','LOGGEDINFO',"COUPONINFO","GUESTINFO"]);
  // const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:0;
  const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:(cookies.GUESTINFO !==undefined)?cookies.GUESTINFO.user_id:0;

  // const queryParams = new URLSearchParams(window.location.search)
  const fetchData = ()=>{
    if (id===0)
    {
      const cookieData = {};
      cookieData.sessId = cookies.SESSIONINFO;
      // if (cookieData.sessId === undefined || cookieData.sessId ==='')
      // {
      //   const unique_id = uuid();
      //   console.log(unique_id,'uuid');
      //   setCookie('SESSIONINFO', JSON.stringify(unique_id));
      //   cookieData.sessId = unique_id;
        
      // }
      if(cookieData.sessId !== undefined || cookieData.sessId !=='')
      {
        const id = cookieData.sessId;
        setLoading(true);
        postCartList(id)
        .then(response => {
          if(response.status=== 200)
          {
            console.log('response.cart_list',response.cart_list);
            respSuccess(response.cart_list);
            if(response.cart_list.length === 0)
              {
                setCartEmpty(false);
              }
            setLoading(false);
          }
        },)
      }
    }
    else
    {
      setLoading(true);
      postCartList(id)
      .then(response => {
        if(response.status=== 200)
        {
          respSuccess(response.cart_list);
          setLoading(false);
        }
      },)
    }  
  }
  // console.log(responseSuccess,'success');

  const cartcount = ()=>{
    if (id===0)
    {
      const cookieData = {};
      cookieData.sessId = cookies.SESSIONINFO;
      if(cookieData.sessId !== undefined || cookieData.sessId !=='')
      {
        const id = cookieData.sessId;
        postCartCount(id)
        .then(response => {
          if(response.status=== 200)
          {
            respCount(response.quantity_list);
          }
        },)
      }
    }
    else
    {
      postCartCount(id)
      .then(response =>
      {
        if(response.status=== 200)
        {
          respCount(response.quantity_list);
        }
      },)
    }
  }
  useEffect(() => {
    localStorage.setItem('billingaddress',0);
    console.log('billingaddress true',localStorage.getItem('billingaddress'));
    fetchData()
    cartcount()
  },[responseCount]);
  // console.log('isLoading',isLoading);
  
  const stepper = (type,index,product_id,cart_id,quantity) => {
    const cNum = {};
    cNum.count = quantity;
    if(type===2 && max > count) {
        // console.log(count,'count');
        // console.log(min,'min');
        cNum.count++;
        setCount(cNum.count);
    }
    if(type ===1 && cNum.count > min) {
        // console.log(count,'count');
        // console.log(min,'min');
        cNum.count--;
        setCount(cNum.count);
    }
    responseSuccess[index].quantity = cNum.count;
    if (id===0)
    {
      const cookieData = {};
      cookieData.sessId = cookies.SESSIONINFO; 
      if(cookieData.sessId !== undefined || cookieData.sessId !=='')
      {
        const id = cookieData.sessId;
        postCartUpdate(id,product_id,cart_id,cNum.count)
      }
    }
    else
    {
      postCartUpdate(id,product_id,cart_id,cNum.count)
    }
  }
  const getSubTotal = ()=>{
    let subTotal = 0;
    if (responseSuccess.length > 0)
    {
      responseSuccess.map((value,index) => (
        subTotal = subTotal + responseSuccess[index].product_price * responseSuccess[index].quantity
      ))
      return subTotal;
    }
  }
  const getAmountPayable = ()=>{
    let amount = 0;
    let c_amount = ccAmount;
    amount = Math.round(getSubTotal() - c_amount);
    return amount;
  }
  const deleteProduct = (cart_id,id) => {
    // setLoading(true);
    // postCartListDelete(cart_id,id)
    // .then(response => {
    //     if(response.status=== 200)
    //     {
    //       fetchData();
    //       cartcount();
    //       setLoading(false);
    //     }
    // })
    
    if(responseSuccess.length===0 || responseCount===1){
      clearCoupon();
    }
  
    if (id===0)
    {
      const cookieData = {};
      cookieData.sessId = cookies.SESSIONINFO; 
      if(cookieData.sessId !== undefined || cookieData.sessId !=='')
      {
        const id = cookieData.sessId;
        postCartListDelete(cart_id,id)
        .then(response => {
            if(response.status=== 200)
            {
              fetchData();
              cartcount();
              //setCartEmpty(false);
            }
        })
      }
    }
    else
    {
      //setLoading(true);
      postCartListDelete(cart_id,id)
      .then(response => {
          if(response.status=== 200)
          {
            fetchData();
            cartcount();
            //setCartEmpty(false);
            //setLoading(false);
          }
      })
    }

  }
  
  const cartDelete = (id) => {
    if (id===0)
    {
      const cookieData = {};
      cookieData.sessId = cookies.SESSIONINFO;
      if(cookieData.sessId !== undefined || cookieData.sessId !=='')
      {
        const id = cookieData.sessId;
        postCartDelete(id)
        .then(response => {
            if(response.status=== 200)
            {
              setCartEmpty(false);
              fetchData();
              cartcount();
            }
        })
        cartcount();
        confirmClose()
      }
    }
    else
    {
      postCartDelete(id)
      .then(response => {
          if(response.status=== 200)
          {
            setCartEmpty(false);
            fetchData();
            cartcount();
          }
          confirmClose()
      })
      cartcount();
    }
  }
  

  const getcartlist = ()=>{
    const content=[];
    if(responseSuccess.length > 0){
      responseSuccess.map((value,index) => (
      content.push(<div className="cart-row" key={index}>
      <Row>
        <Col lg={1} md={1} xs={1} >
          <div className="cart-content">
            <Link to="" className="delete-product"><Icon icon="fluent:delete-32-regular" onClick = {(e) => {deleteProduct(value.cart_id,id);onApply()}} /></Link>
          </div>
        </Col>
        <Col lg={5} md={5} xs={3} >
          <div className="cart-content product">
            <div className="thumb">
              <img src={value.product_image} className="img-fluid" alt="" title=""></img>
            </div>
            <div className="info">
              <h4>{value.product_name} </h4>
              <p>{value.variant_name} {value.unit_name}</p>
            </div>
          </div>
        </Col>
        <Col lg={6} md={6} xs={8}>
          <Row>
            {/* <Col xs={12} className="mob-prodcut">
              <h4>Beet o malt plus - 250 G </h4>
            </Col> */}
            <Col lg={4} md={4} xs={12} className="col">
              <div className="cart-content price">
                <p>₹{value.product_price}</p>
              </div>
            </Col>
            <Col lg={4} md={4} xs={12} className="col">
              <div className="cart-content">
              <div class="counter-qty">
                <div class="numbers">
                  <Icon icon="ph:minus-bold" onClick = {(e) => {stepper(1,index,value.product_id,value.cart_id,value.quantity);onApply()}} />
                </div>
                <div class="totals">
                  <p>{responseSuccess[index].quantity}</p>
                </div>
                <div class="numbers">
                  <Icon icon="ph:plus-bold" onClick = {(e) => {stepper(2,index,value.product_id,value.cart_id,value.quantity);onApply()}} />
                </div>
              </div>
              </div>
            </Col>
            <Col lg={4} md={4} xs={12} className="col">
              <div className="cart-content total">
              <p>₹{responseSuccess[index].quantity * responseSuccess[index].product_price}</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>)
    ))
    }
    return content;
  }
  
  const getMyCart = ()=>{
    return <Row>            
      <Col lg={8}>
        <div className="main-heading3 icon-right">
          <h3>My Cart</h3>
          <Link className="clear-cart" onClick={(e) => {setShowConfirm(true);clearCoupon()}}
             ><Icon icon="mdi:close" /> Clear Cart</Link>
        </div>

        {isLoading===true?<div className='cart-loader'>
          <div className='loader-icon'></div>
        </div>:''}

        <div className="cart-box">
          <div className="cart-header">
            <Row>
              <Col lg={1} md={1} xs={1}>
                <div className="cart-content">
                  
                </div>
              </Col>
              <Col lg={5} md={5} xs={4}>
                <div className="cart-content">
                  Product
                </div>
              </Col>
              <Col lg={2} md={2} xs={2}>
                <div className="cart-content">
                  Price
                </div>
              </Col>
              <Col lg={2} md={2} xs={3}>
                <div className="cart-content">
                  Qty
                </div>
              </Col>
              <Col lg={2} md={2} xs={2}>
                <div className="cart-content text-end">
                  Total
                </div>
              </Col>
            </Row>
          </div>

          <div className="cart-body">
            {(responseSuccess.length > 0)?getcartlist():getCartEmpty()}
           
            
          </div>
          <div className="cart-footer">
            <Row>
              <Col lg={12}>
                <h3><span>Sub Total </span> ₹{getSubTotal()}</h3>
              </Col>
            </Row>
          </div>
          <div className="mt-3">
            <Link className="btn btn-lg btn-outline-primary"  to="/">Continue Shopping</Link>
          </div>
          
        </div>     
      </Col>
      <Col lg={4}>                
        <Card className="card cart">
          <Card.Header>
          Order summary
          </Card.Header>
          <Card.Body>
            <div className='coupon-box'>
                <h3>Coupon code</h3>
                <p>If you have a coupon code, please enter it in <br/> the box below</p>
                <InputGroup>
                  <Form.Control
                    placeholder="Enter Coupon Code"
                    name="cc"
                    value={cc}
                    onChange = {onChangeCC}
                    aria-label=""
                    aria-describedby=""
                  />
                  <Button variant="primary" onClick = {(e)=>onApply()}><Icon icon="ph:gift-light" /> Apply </Button >
                  {cc!==''?<Link className="coupon-clear" onClick = {(e)=>clearCoupon()}><Icon icon="material-symbols:close" /></Link>:''}
                </InputGroup>
                {formErrors.cc!=='' && formErrors.cc!==undefined && (<Form.Text className='error text-danger'>{formErrors.cc}</Form.Text>)}
                {(Object.keys(formErrors).length===0 || formErrors.cc ==='') && <Form.Text className='error text-success text-bold'>{coupon_message}</Form.Text>}
                {/* <Form.Text className='error text-success text-bold'>{cookies.COUPONINFO?coupon_message:ccApplied}</Form.Text> */}
            </div>
            <p className="desc">Additional costs are calculated based on values you have entered.</p>
            <div className="label-price mb-3">
              <p>Order Subtotal  <span>₹{getSubTotal()?getSubTotal():0}</span></p>
              {/* <p>Shipping and handling <span>₹100</span></p> */}
              <p>Coupon <span>₹{ccAmount}</span></p>
              <p className="bold">Amount Payable <span>₹{getAmountPayable()?getAmountPayable():0}</span></p>
            </div>
            {/* {getCartEmpty() && responseSuccess.length === 0 ?'':<Button className="btn btn-lg btn-primary w-100" to="/checkout">Proceed to Checkout</Button>} */}
            {getCartEmpty() && responseSuccess.length === 0 ?'':<Link className="btn btn-lg btn-primary w-100" to="/checkout">Proceed to Checkout</Link>}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  }
  const getCartEmpty = ()=>{
    return <div className="cart-empty">
      <img src="/assets/images/cart-empty.png" className="img-fluid" alt="" title=""></img>
      <h5><br></br>Your cart is currently empty</h5>
      <p>Before proceed to checkout, you must add some products to your cart, <br/> You will find a lot of products on our 'Shop' page.</p>
      <div className="mt-3">
        <Link className="btn btn-lg btn-primary"  to="/productlist">Continue Shopping</Link>
      </div>
    </div>
  }
   const clearCoupon = (e)=>{

    setCC("");
    //const cookies1 = new Cookies();
    //cookies1.remove('COUPONINFO');
    //setCookie('COUPONINFO', JSON.stringify({coupon_code:'',message:'',amount:0}));
    cookies.COUPONINFO.coupon_code = '';
    cookies.COUPONINFO.message = '';
    cookies.COUPONINFO.amount = 0;
     setTimeout(()=>{
      getCouponData();
    },500);
    
  } 
    /* setCC("");
    setCouponCode("");
    setCouponMessage("");
    setCCAmount(0); */
    
  
  /* const clearCookies = () => {
    const cookies1 = new Cookies();
    cookies1.remove('GUESTINFO');
  

  } */
  const onChangeCC = (e)=>{
    const cookies1 = new Cookies();
    cookies1.remove('COUPONINFO');
    const eTarget = e.target;
    setCC(eTarget.value);
    setCCAmount(0);
    setCCApplied('');
    setCouponMessage('');
  }
  const onApply = ()=>
  {

    const error = {};
    if(cc ==='') error.cc = 'Enter Coupon Code'
      setInvalidCC("");
    if(Object.keys(error).length === 0)
    {
      if (id===0)
      {
        const cookieData = {};
        cookieData.sessId = cookies.SESSIONINFO;
        if(cookieData.sessId !== undefined || cookieData.sessId !=='')
        {
          const id = cookieData.sessId;
          postCouponCode(id,cc)
          .then(response => {
            console.log('coupon resp',response);
          if(response.status=== 200)
          {
            setCCAmount(response.amt);
            setCCApplied(response.message);
            const cookieData = {};
            setCookie('COUPONINFO', JSON.stringify({coupon_code:cc,message:response.message,amount:response.amt}));
            cookieData.couponcode = cookies.COUPONINFO;
            setCouponMessage(response.message);
            setCCApplied(cookieData.couponcode.message);
            //getCouponData()

          }
          else if(response.status=== 400)
          {
            setCCApplied("");
            setInvalidCC("Invalid Coupon code");
            error.cc = (response.message)
            //error.cc = 'Invalid Coupon code'
            //clearCookie()

            // setCouponCode("");
            // setCouponAmount("");
            // setCouponMessage("");
          }
          },)
        
        }
        formError(error);
      }
      else
      {
        postCouponCode(id,cc)
        .then(response => {
        if(response.status=== 200)
        {
          setCCAmount(response.amt);
          setCCApplied(response.message);
          const cookieData = {};
          setCookie('COUPONINFO', JSON.stringify({coupon_code:cc,message:response.message,amount:response.amt}));
          cookieData.couponcode = cookies.COUPONINFO;
          setCouponMessage(response.message);
          setCCApplied(cookieData.couponcode.message);
          //getCouponData()

        }
        else if(response.status=== 400)
        {
          setCCApplied("");
          setInvalidCC("Invalid Coupon code");
          error.cc = (response.message)
          //clearCookie()

          // setCouponCode("");
          // setCouponAmount("");
          // setCouponMessage("");
        }
        },)
      }
      formError(error);

    }
    
  }
  
  const getCouponData = ()=>{ 
    const cookieData = {};
    cookieData.couponcode = cookies.COUPONINFO;
    if (cookies.COUPONINFO !== undefined && cookies.COUPONINFO.coupon_code !=='')
    {
      setCC(cookieData.couponcode.coupon_code);
      setCCAmount(cookieData.couponcode.amount);
      setCouponCode(cookieData.couponcode.coupon_code);
      setCouponMessage(cookieData.couponcode.message);
      setCouponAmount(cookieData.couponcode.amount);
    }
    else
    {
      setCookie('COUPONINFO', JSON.stringify({coupon_code:'',message:'',amount:0}));
      setCouponCode('');
      setCC('');
      setCouponMessage('');
      setCouponAmount(0);
      setCCAmount(0);
    }

  }
  // console.log('coupon_message',coupon_message);
  // console.log('formErrors',formErrors)
  useEffect(() => {

    localStorage.setItem('guest_continue',0)

    let order_check = localStorage.getItem('continue_order') ;
    //let address_submit = localStorage.getItem('address_submit') ;
    console.log('continue_order',order_check);
    if(order_check==0 && cookies.LOGGEDINFO!==undefined){
      localStorage.setItem('continue_order',1)
    }else{
      if(order_check==0){
        localStorage.setItem('continue_order',1)
      }

    }
    //localStorage.setItem('continue_order',0)

    /* if(address_submit==0){
      localStorage.setItem('address_submit',1)
    }
 */
    if(localStorage.guest_username!==undefined && localStorage.guest_phone!==undefined && localStorage.guestotp!==''){
      localStorage.setItem('guest_username','');
      localStorage.setItem('guest_phone','');
      localStorage.setItem('guestotp','');
      console.log('cartjjjj');
      
      //clearCookie();
    }
    if(cookies.LOGGEDINFO===undefined){
      localStorage.removeItem("shipId")
    }
    
    localStorage.setItem('defaultGuest',0);
    console.log('localStoragedefaultGuest',localStorage.defaultGuest)

   // const clearCookie = () => {
      
    
    //  }

    console.log('responseCount',responseCount);
    getCouponData();
    onApply();
  // },[responseSuccess,count,cartcount,coupon_message,coupon_code,cc]);
},[responseSuccess,count]);


  
  return (
    <div>
        <Header cartCount={responseCount} />
        
        <section className="section header-nxt">
          <Container fluid="md">
              {(responseSuccess.length > 0)?getMyCart():getCartEmpty()}

              {/* {(responseSuccess.length ===0 && !isEmpty)?getCartEmpty():getMyCart()} */}
          </Container>
        </section>
        <Footer />
        <Modal show={showConfirm} onHide={confirmClose} size="md" className="modal-alert-option">
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='form-style1'>
            <Row>
              Are you sure want to clear the cart?
            </Row>              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={(e) => cartDelete(id)}>
              Ok
            </Button>
            <Button variant="light" onClick={confirmClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
};

export default Cart;
