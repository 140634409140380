import React,{useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import {useCookies,Cookies} from 'react-cookie';
import { postGeneralData } from "../services/serviceapi";

const Footer = () => {
	const cookies = useCookies();
	const [whatsapplink,setWhatsapp] = useState("");
	const [facebooklink,setFacebook] = useState("");
	const [instagramlink,setInstagram] =useState("");
	const [youtubelink,setYoutube] = useState("");
	const [companyname,setCompanyName] = useState("");
	const [contactnumber,setContactNumber] = useState("");
	const [address,setAddress] = useState("");
	const [email,setEmail] = useState("");

	const getMyAccount = ()=> {
		return <ul>
			<li><Link to="/myprofile" className="">My Profile</Link></li>
			<li><Link to="/myorders" className="">My Orders</Link></li>
			<li><Link to="/cart" className="">My Cart</Link></li>
			<li><Link to="/shippingaddress" className="">My Addresses </Link></li>
		</ul>
	}
	const getSignIn = ()=> {
		return <ul>
			<li><Link to="/login" className="">My Profile</Link></li>
			<li><Link to="/login" className="">My Orders</Link></li>
			<li><Link to="/login" className="">My Cart</Link></li>
			<li><Link to="/login" className="">My Addresses </Link></li>
		</ul>
	}
	const getGeneralData = ()=>{
		postGeneralData()
		.then(response => {
		if(response.status=== 200)
		{
			// console.log(response,'response');
			setWhatsapp(response.site_details.whats_app_link);
			setFacebook(response.site_details.facebook_link);
			setInstagram(response.site_details.insta_link);
			setYoutube(response.site_details.youtube_link);
			setCompanyName(response.site_details.company_name);
			setContactNumber(response.site_details.contact_number);
			setAddress(response.site_details.address);
			setEmail(response.site_details.contact_email);

		}
		},)
	}
	useEffect(() => {
		getGeneralData()
	  },[]);
return (
	<footer>
		<Container fluid="md">
			<Row>
				<Col lg={2} md={4} xs={6}>
					<div className="footer-widget">
						<h3>Pages</h3>
						<ul>
							<li><Link to="/" className="">Home</Link></li>
							<li><Link to="/productlist" className="">Products</Link></li>
							<li><Link to="/aboutus" className="">About Company</Link></li>
							<li><Link to="/contact" className="">Contact Us</Link></li>
						</ul>
					</div>
				</Col>
				<Col lg={2} md={4} xs={6}>
					<div className="footer-widget">
						<h3>Account</h3>
							{cookies[0].LOGGEDINFO?getMyAccount():getSignIn()}
					</div>
				</Col>
				<Col lg={2} md={4}>
					<div className="footer-widget">
						<h3>Our Policies</h3>
						<ul>
							<li><Link to="/privacypolicy" className="">Privacy Policy</Link></li>
							<li><Link to="/termscondition" className="">Terms & Conditions</Link></li>
							<li><Link to="/returnpolicy" className="">Return Policy</Link></li>
							<li><Link to="/shippingpolicy" className="">Shipping Policy</Link></li>
						</ul>
					</div>
				</Col>
				<Col lg={3} md={6}>
					<div className="footer-widget">
						<h3>Contact</h3>
						<p>{address}<br/><br/><Link to="/" className=""><strong>{contactnumber}</strong></Link><Link to="/" className="maillink"><strong>{email}</strong></Link></p>					
					</div>
				</Col>
				<Col lg={3} md={6}>
					<div className="footer-widget social">
						<h3>Social Networks</h3>
						<ul className="social-links">
							<li><Link to={facebooklink!==''?facebooklink:"/"} className="" target="_blank" title="Facebook"><img src="../assets/images/social-fb.svg" alt="" /></Link></li>
							<li><Link to={instagramlink!==''?instagramlink:"/"} className="" target="_blank" title="Instagram"><img src="../assets/images/social-insta.svg" alt="" /></Link></li>
							<li><Link to={youtubelink!==''?youtubelink:"/"} className="" target="_blank" title="Youtube"><img src="../assets/images/social-youtube.svg" alt="" /></Link></li>
							<li><Link to={youtubelink!==''?whatsapplink:'/'} className="" target="_blank" title="Whatsapp"><img src="../assets/images/social-whatsapp.svg" alt="" /></Link></li>
						</ul>
					</div>
				</Col>
			</Row>
			<Row>
				<Col className="copyright">
					<p>{companyname} © {new Date().getFullYear()}. All Rights Reserved.</p>
				</Col>
			</Row>
		</Container>
	</footer>
);
};

export default Footer;
