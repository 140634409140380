import React,{useEffect,useState} from "react";
import { Button } from "react-bootstrap";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {useCookies,Cookies} from 'react-cookie';
import { Link,useNavigate } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import {getAllProducts,postCartList,postCartCount} from "../services/serviceapi";

const Header = (params) => {
	console.log('paramsx',params);
	const [product, setProduct] = useState([]);
	const [responseSuccess,respSuccess] = useState([]);
	const [responseCount,respCount] = useState(0);
	const [userStatus, respUserStatus] = useState(1);
	const navigate = useNavigate();
	const [cookies, setCookie] = useCookies(['SESSIONINFO','COUPONINFO']);
	// const cookies = useCookies();
	// const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:0;
	const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:(cookies.GUESTINFO !==undefined)?cookies.GUESTINFO.user_id:0;

	const handleOpen = (id) => {
		postCartList(id)
		.then(response => {
		  if(response.status=== 200)
		  {
		  respSuccess(response.message);
		  }
		},)
	}
	const getproducts = ()=>{
		getAllProducts()
		  .then(response => {
			if(response.status=== 200 && response.products.length > 0)
			setProduct(response.products);
		})
		return [product];
	}
	const cartcount = (id)=>{
		if (id===0)
		{
			const cookieData = {};
			cookieData.sessId = cookies.SESSIONINFO; 
			if (cookieData.sessId === undefined || cookieData.sessId ==='')
			{
				const unique_id = uuid();
				setCookie('SESSIONINFO', JSON.stringify(unique_id));
				cookieData.sessId = unique_id;
			}
			if(cookieData.sessId !== undefined || cookieData.sessId !=='')
			{
				const id = cookieData.sessId;
				postCartCount(id)
				.then(response => {
					if(response.status=== 200)
					{
						respCount(response.quantity_list);
						respUserStatus(response.user_status);
					}
				})
				// if(userStatus===0)
				// {
				// 	let path = `/login`; 
				// 	navigate(path);
				// }
			}
		}
		else
		{
			postCartCount(id)
			.then(response => {
				if(response.status=== 200)
				{
					respCount(response.quantity_list);
					respUserStatus(response.user_status);
				}
			},)
		}
		if(userStatus===0 && cookies.LOGGEDINFO.user_id)
			{
				// let path = `/login`; 
				// navigate(path);
				const cookies1 = new Cookies();
  				cookies1.remove('LOGGEDINFO');
				cookies1.remove('COUPONINFO');
			}
	}
	
    useEffect(() => {
		getproducts()
		cartcount(id)
    // },[cartcount]);
	},[responseCount,params.cartCount,]);

  const getproductlist = ()=>{
    const content=[];
	
    product.map((value,index) => {
		const lowerString = value.product_name.toLowerCase();
      content.push(
	<div className="content">
	  <Link key={index} className="dropdown-item"
	    to={`/product-detail?#${lowerString}`.split(' ').join('-')}
		state={{value}}

	   >{value.product_name}</Link>
	</div>
	  )
	  })
    return content;
  }
  
  const clearCookie = () => {
	const cookies1 = new Cookies();
  	cookies1.remove('LOGGEDINFO');
	cookies1.remove('COUPONINFO');
	localStorage.removeItem("shipId")
	localStorage.removeItem("continue_order")
	localStorage.removeItem('billing_check')
  }
 /*  const ClearLocalstorage = () => {
	localStorage.removeItem("name");
	localStorage.removeItem("mobile");
	localStorage.removeItem("email");
	localStorage.removeItem("state");
	localStorage.removeItem("city");
	localStorage.removeItem("doorstreet");
	localStorage.removeItem("address");
	localStorage.removeItem("pincode");
   
  } */
  const getMyAccount = () => {
	return <NavDropdown title="My Account +" id="collasible-nav-dropdown">		
		<Link to="/myprofile" className="dropdown-item">My Profile</Link>						
		<Link to="/myorders" className="dropdown-item">My Orders</Link>			
		{/* <Link to="/TrackOrders" className="dropdown-item">Track Orders</Link> */}
		<Link to="/shippingaddress" className="dropdown-item">My Addresses</Link>
		<Link to="/changepassword" className="dropdown-item">Change Password</Link>				
		<Link to="/login" className="dropdown-item" onClick = {(e) => clearCookie()}
		>Sign Out</Link>
	</NavDropdown>
  }
	return (		
		<header className="header">
			<Navbar fixed="top" expand="lg">
				<Container>
					<Link to="/" className="navbar-brand"><img src="/assets/images/logo.png" alt="" /></Link>
					<div className="">
						{responseSuccess}
					</div>
        			<Navbar.Toggle aria-controls="responsive-navbar-nav" />					
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mx-auto">
							<Link to="/" className={window.location.pathname === "/"?"nav-link active": "nav-link"}>Home</Link>
							<NavDropdown active={window.location.pathname === '/product-detail'?true:false} title="Products +" id="collasible-nav-dropdown">
								{getproductlist()}
							</NavDropdown>
							<Link to="/aboutus" className={window.location.pathname === "/aboutus"?"nav-link active": "nav-link"}>Company</Link>
							<Link to="/contact#topPosition" className={window.location.pathname === "/contact"?"nav-link active": "nav-link"}>Contact Us</Link>
							{cookies.LOGGEDINFO?getMyAccount():<Link to="/login" className={window.location.pathname === "/login"?"nav-link active": "nav-link"}>Sign In</Link>}
						</Nav>
						<Nav className="d-none d-lg-block">
							<Link to="/cart" className="nav-link"
							 onClick = {(e) => handleOpen(id)}
							> <img src="../assets/images/icon-cart.svg" alt="" />My Cart ({(params.cartCount !==undefined && params.cartCount !=='')?params.cartCount:responseCount})</Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
				<Nav className="header-cart">
					<Link to="/cart" className="nav-link"
						onClick = {(e) => handleOpen(id)}
					> <img src="../assets/images/icon-cart.svg" alt="" />My Cart ({(params.cartCount !==undefined && params.cartCount !=='')?params.cartCount:responseCount})</Link>
				</Nav>
			</Navbar>			
		</header>
	);
};

export default Header;
