import React,{useEffect,useState} from "react";
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
// import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {getAllBanners} from "../services/serviceapi";

const HomeBanner = () => {
  const [banner, setBanner] = useState([]);
  const [isLoading,setLoading] = useState(false);
  //const [bannerName, setBannerName] = useState('');

  const getbanners = ()=>{
    getAllBanners()
    .then(response => {
        if(response.status=== 200 && response.banners!=='')
        setBanner(response.banners);
    },)
    return [banner];
  }
  useEffect(() => {
    getbanners()
    getBannerLength()
  },[]);

  const getLoaderList = ()=>{
    const content=[];
    banner.map((ban,index) => (

      content.push(<Row>
        <Col lg={12}>
           {/* <h3>Style 1</h3> */}
           <div className="skeleton banner-loader"></div>               
        </Col>
      </Row>)
    ))
    setTimeout(()=>{
      setLoading(true);
    },1200)
    return content;
}
 
  const getBannerLength = () => {
    console.log('bannerlength',banner)

  }

  const getcarousalitems = ()=>{
    const content=[];
    banner.map((value,index) => (
      console.log('home bannar',value),
      //console.log('lowerString bannar',lowerString),
      content.push(<Carousel.Item>
        <Link  key={index} /* target="_blank" */ to={`/product-detail?#${value.product_name.toLowerCase()}`.split(' ').join('-')} state={{value}}
 className=""> {value.product_name}
            <img className="d-block w-100" src={value.banner_img} alt="" />
        </Link>
      </Carousel.Item>
         )
    ))  
    return content;
}


	return (		
		<section className="banner">
          <Carousel>
            {isLoading===false?getLoaderList():getcarousalitems()}
            {/* <Row>
              <Col lg={12}>
                 <h3>Style 1</h3>
                 <div className="skeleton banner-loader"></div>               
              </Col>
            </Row> */}
          </Carousel>
          
        </section>   
	);
};

export default HomeBanner;
