import React,{useState} from "react";
import {useCookies} from 'react-cookie';
import { Link,useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Icon } from '@iconify/react';
import { postLogIn } from "../services/serviceapi";
import { v4 as uuid } from 'uuid';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const Login = () => { 
const [mobilenumber,setMobile] = useState("");

const [password, setPwd] = useState("");
const defData = {mobilenumber:'',password:''};
const [formErrors,formError] = useState(defData);
const [responseError,respError] = useState('');
const [passwordType, setPasswordType] = useState("password");
const [cookies, setCookie] = useCookies(['LOGGEDINFO','SESSIONINFO']);
const navigate = useNavigate();


const togglePassword =()=>{
  if(passwordType==="password")
  {
   setPasswordType("text");
  }else{
    setPasswordType("password");
  }
}
// const re=/\S+@\S+\.\S+/;

function isValidMobileNumber(mobilenumber) {
  // return /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(mobilenumber);
  return /^[6-9][0-9]{9}$/i.test(mobilenumber);

} 

const onChange = (e) => {
  const eTarget = e.target;
  // console.log(eTarget.value,"value")
  switch(eTarget.name) {
  case 'mobilenumber':
    setMobile(eTarget.value)
  break;
  
  case 'password':
   setPwd(eTarget.value)
  break;
  
  default:
  break;
 }
}

const onSubmit = (e) => {
  // console.log('cookies',cookies);
  const error = {}
  if(mobilenumber==='' || mobilenumber===null) error.mobilenumber = 'Enter Mobile number'
  else if(mobilenumber!=="" && !isValidMobileNumber(mobilenumber)) 
    {
      error.mobilenumber = ' Please enter valid mobile number';
    }
  if(password==='' || password===null) error.password = 'Enter Password'

  const cookieData = {};
  cookieData.sessId = cookies.SESSIONINFO;
  if (cookieData.sessId === undefined || cookieData.sessId ==='')
  {
    const unique_id = uuid();
    // console.log(unique_id,'uuid');
    setCookie('SESSIONINFO', JSON.stringify(unique_id));
    cookieData.sessId = unique_id;
  }
  
  if(Object.keys(error).length === 0){
    postLogIn(mobilenumber,password,cookieData.sessId)
    .then(response => {
      // console.log(response.user_id,'response');
      if(response.status=== 200)
      {
        if(cookies.LOGGEDINFO!==undefined||cookies.LOGGEDINFO!=='')
        {
          // console.log('sdsdsd');
        setCookie('LOGGEDINFO', JSON.stringify({user_id:response.user_id}));
        let path = `/productlist`; 
        setMobile('');
        setPwd('');
        navigate(path);
        //ClearLocalstorage();
        }
      }
      else
      {
        respError(response.message);
      }
  },)
  }
  formError(error)
}

/* const ClearLocalstorage = () => {
  localStorage.removeItem("name");
  localStorage.removeItem("mobile");
  localStorage.removeItem("email");
  localStorage.removeItem("state");
  localStorage.removeItem("city");
  localStorage.removeItem("doorstreet");
  localStorage.removeItem("address");
  localStorage.removeItem("pincode");
 
} */

  return (
    <div>
        <Header />
        <section className="section header-nxt">
          <Container fluid="md">
            <Row>            
              <Col lg={4} className="offset-lg-4">
                <div className="login-cont">
                  <h3>Sign In</h3>
                  <Form className="mt-4">

                    <Form.Group className="form-group mb-3" controlId="formBasicEmail">
                      <Form.Label>Mobile number <span className="req">*</span></Form.Label>
                      <Form.Control type="text" maxLength = "10" autoComplete="off" name="mobilenumber" value={mobilenumber} onChange={onChange} placeholder="Enter Mobile number" />
                      {formErrors.mobilenumber!=='' && formErrors.mobilenumber!==undefined && (<Form.Text className='error text-danger'>{formErrors.mobilenumber}</Form.Text>)}
                    </Form.Group>

                    <Form.Group className="form-group mb-1" controlId="formBasicPassword">
                      <Form.Label>Password <span className="req">*</span></Form.Label>
                      <Form.Control type={passwordType} name="password" maxLength="20" value={password} autoComplete="off" onChange={onChange} placeholder="Password" />
                      <div onClick={togglePassword} className="eye">{(passwordType==="password")?(<Icon icon="mdi:eye-off" />):<Icon icon="mdi:eye" />}</div>
                      {formErrors.password!=='' && formErrors.password!==undefined && (<Form.Text className='error text-danger'>{formErrors.password}</Form.Text>)}
                      {responseError !=='' && responseError !==undefined && (<Form.Text className='error text-danger'>{responseError}</Form.Text>)}
                    </Form.Group>
                    <div className="forgot-row mt-2 mb-3">
                      <Link to="/forgotpassword" className="fwd-password">Forgot Password?</Link>
                    </div>
                    <Button variant="primary" className="btn-lg w-100" onClick={onSubmit} >Submit</Button>
                  </Form>
                  <p className="reg-text">Don't have an account? <Link to="/Register">Register here</Link></p>
                </div>                
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
    </div>
  );
};

export default Login;
